.tenant-forms-dialog {
  .tenant-forms-dialog-content {
    display: flex;
    flex-direction: column;
    width: 330px;
    min-height: 130px;
    margin-top: 30px;
    padding: 0 24px;
  }

  .tenant-forms-dialog-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
