.reminder-notification-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 370px;

  .reminder-notification-message {
    padding: 0 15px;
  }

  .reminder-notification-btn {
    height: 30px;
    width: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #616161;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background-color: aliceblue;
    }
  }

  .reminder-notification-btn-disabled {
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }

  .back-btn {
    justify-content: flex-end;
  }

  .forward-btn {
    justify-content: center;
  }
}
