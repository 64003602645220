.file-uploads-list-container {
  overflow-y: auto;

  .no-files-message {
    text-align: center;
  }

  .file-uploads-list {
    padding: 10px;
  }
}
