@font-face {
  font-family: 'tabeeb';
  src: url('TabeebSidebar/static/css/~@tabeeb/assets/fonts/tabeeb.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
[class^='tabeeb-icon-'] {
  font-family: 'tabeeb' !important;
  font-style: normal;
  font-weight: normal;
  speak: none;
  font-variant: normal;
  text-transform: none;
}
[class^='tabeeb-icon-'] .active {
  color: #0089cf;
}
[class^='tabeeb-icon-'] .disabled {
  color: darkgray;
}
/* 'î ‡' */
.tabeeb-icon-play:before {
  content: '\e808';
}
/* 'î ‰' */
.tabeeb-icon-search:before {
  content: '\e80a';
}
/* 'î §' */
.tabeeb-icon-trash-empty:before {
  content: '\e828';
}
.tabeeb-icon-edit-black:before {
  content: '\e857';
}
.tabeeb-icon-lock:before {
  content: '\e85b';
}
/* '' */
.icon-chat {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/ic_chat_white_24px.svg') 100% no-repeat;
}
.icon-forms {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/ic_forms_in_white_24px.svg') 100% no-repeat;
}
.icon-activities {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/search_24px.svg') 100% no-repeat;
}
.icon-file-uploads {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/cloud_upload-24px.svg') 100% no-repeat;
}
.icon-users {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/ic_group_black_24px.svg') 100% no-repeat;
}
.icon-custom-video {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/ic_custom_video_black_24px.svg') 100% no-repeat;
}
.icon-spatial-view {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/ic_spatial_view_24px.svg') 100% no-repeat;
}
.icon-3d-model {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/baseline-3d_rotation-24px.svg') 100% no-repeat;
}
.icon-add-users {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/ic_add_users_24px.svg') 100% no-repeat;
}
.icon-gallery {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/ic_gallery_black_24px.svg') 100% no-repeat;
}
.icon-share {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/icon_share_24px.svg') 100% no-repeat;
}
.icon-review {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/icon_review_24px.svg') 100% no-repeat;
}
.icon-structure-model {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/square_foot-24px.svg') 100% no-repeat;
}
.icon-tower-side-profile {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/cell_tower_black_24dp.svg') 100% no-repeat;
}
.tabeeb-icon-export-csv {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/baseline-get_app-24px.svg') 100% no-repeat;
}
.tabeeb-icon-advanced-filter {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/funnel-noborder-20px.svg') 100% no-repeat;
}
.tabeeb-icon-progress {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/Rolling-1s-24px.svg') 100% no-repeat;
}
.icon-bentley-model {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/baseline-b-letter-24px.svg') 100% no-repeat;
}
.icon-bentley-analytics-report {
  background: url('TabeebSidebar/static/css/~@tabeeb/assets/icons/baseline-measure-area-24px.svg') 100% no-repeat;
}
.tabeebSidebar {
  height: calc(100%) !important;
}
.sidebarApp {
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding-top: 12px;
}
.tabeebScrollable {
  -webkit-overflow-scrolling: touch;
}
.contentWrapper {
  height: 100%;
}
.tabeebSideBarNavbar {
  background-color: #c4c4c4;
  display: table;
  display: flex;
  flex-direction: column;
  min-width: 50px;
  max-width: 50px;
  margin: 0;
  list-style: none;
}
.tabeebSideBarNavbar li {
  cursor: pointer;
  position: relative;
}
.tabeebSideBarNavbar li span {
  display: block;
  width: 24px;
  height: 24px;
  margin: 12px 13px;
  color: rgba(0, 0, 0, 0.54);
}
.tabeebSideBarNavbar li b {
  position: absolute;
  top: 5px;
  left: 25px;
  background-color: #F44336;
  border-radius: 3px;
  display: block;
  min-width: 18px;
  height: 18px;
  text-align: center;
  color: #ffffff;
}
.tabeebSideBarNavbar li[data-name='chat'],
.tabeebSideBarNavbar li[data-name='fileUploads'] {
  position: relative;
}
.tabeebSideBarNavbar .active {
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
.tabeebSideBarNavbar-bottom {
  flex-direction: row;
  min-width: auto;
  max-width: auto;
  margin-top: 20px;
}
li[data-name='custom-video'],
li[data-name='spatial-view'],
li[data-name='3d-model'],
li[data-name='bentley-model'],
li[data-name='bentley-analytics-report'],
li[data-name='share-session'],
li[data-name='review-session'],
li[data-name='structure-model'],
li[data-name='linked-contents-pages'],
li[data-name='tower-side-profile'],
li[data-name='2d-line-drawing'],
li[data-name='classification-annotations'],
li[data-name='splat-model'] {
  background-color: #c4c4c4;
  color: #333;
}
.no-navbar .tabeebSidebarPanelContainer {
  padding-right: 0;
}
.tabeebSidebarPanelContainer {
  height: 100%;
  z-index: 10;
  padding-right: 50px;
}
.tabeebSidebarPanelContainer.no-open-panels {
  padding-right: 0;
  pointer-events: none;
  width: 0;
}
.tabeebSidebarPanelContainer.no-open-panels > div {
  display: none !important;
}
.tabeebSidebarPanelContainer > .tabeebSidebarPanel[data-panel-name="forms"] {
  overflow-y: hidden !important;
}
.tabeebSidebarPanelContainer > .tabeebSidebarPanel {
  height: 100%;
  background: #3c3e43;
  display: none;
  color: white;
  transition: right 0.5s;
  max-height: 100%;
  position: relative;
  /*
    This hack is so videos can still be displayed on the tabeeb canvas when the video panel isn't open
    */
}
.tabeebSidebarPanelContainer > .tabeebSidebarPanel.tabeebSidebarPresentationPanel {
  display: block;
  width: 100% !important;
  height: 37px !important;
}
.tabeebSidebarPanelContainer > .tabeebSidebarPanel.tabeebSidebarPresentationPanel.active {
  width: 100% !important;
  height: 100% !important;
}
.tabeebSidebarPanelContainer > .tabeebSidebarPanel.tabeebSidebarPresentationPanel:not(.active) > div {
  position: absolute;
  left: -5000px;
}
.tabeebSidebarPanelContainer > .tabeebSidebarPanel.active {
  display: block;
}
.sidebarContainer .tabeebSidebarPresentationPanel .tabeebControls {
  display: none;
}
.sidebarContainer .tabeebSidebarPresentationPanel:not(.active) {
  max-height: 0 !important;
}
.sidebarContainer.presentationMode .tabeebSidebarPresentationPanel .tabeebControls {
  display: block;
}
.sidebarContainer.presentationMode .tabeebSidebarPresentationPanel:not(.active) {
  overflow: hidden;
  max-height: 100% !important;
}
.sidebarContainer.presentationMode .tabeebSidebarPresentationPanel:not(.active) .tabeebControls {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
.tabeebPresentationToolbar {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  float: left;
  width: 60%;
  margin-right: 20px;
  flex-grow: 1;
  overflow: hidden;
  min-width: 200px;
  user-select: none;
}
.tabeebPresentationToolbar .tabeebPresentationContent {
  position: relative;
  border: 0px #e0e0e0 solid;
  width: 100%;
  height: calc(100% - 50px - 40px - 200px);
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  background-color: #404040;
  border: 1px solid #c4c4c4;
}
.tabeebPresentationToolbar .tabeebPresentationContent .largeVideoWrapper {
  background-color: #000000;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  text-align: center;
  z-index: 10;
  line-height: 0;
}
.tabeebPresentationToolbar .tabeebPresentationContent .largeVideoWrapper .largeVideo {
  height: 100%;
  margin: auto;
}
.tabeebPresentationToolbar .tabeebPresentationContent .largeVideoUserName {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 5px;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  z-index: 100;
  text-align: center;
}
.tabeebPresentationToolbar .tabeebPresentationContent .largeVideoUserName.zoomed {
  margin-bottom: 10px;
  margin-right: 10px;
}
.cont {
  width: 100%;
  height: 200px;
  position: relative;
}
.tabeebInviteDialog {
  width: 600px !important;
  padding: 5px;
}
.tabeebInviteDialog .tabeebInviteDialogBody {
  height: 300px;
  overflow-y: auto;
}
.tabeebInviteDialog .tabeebInviteDialogBody .tabeebFoundUserList {
  list-style: none;
  display: flex;
  flex-direction: column;
}
.tabeebInviteDialog .tabeebInviteDialogBody .tabeebFoundUserList .tabeebFoundUser {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  justify-content: space-between;
}
.tabeebInviteDialog .tabeebInviteDialogBody .tabeebFoundUserList .tabeebFoundUser .tabeebFoundUserBody {
  display: flex;
  align-items: center;
}
.tabeebInviteDialog .tabeebInviteDialogBody .tabeebFoundUserList .tabeebFoundUser .tabeebFoundUserBody .tabeebInviteUser {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tabeebInviteDialog .tabeebInviteDialogBody .tabeebFoundUserList .tabeebFoundUser .tabeebFoundUserBody .tabeebInviteUserName {
  margin-left: 0;
}
.tabeebInviteDialog .tabeebInviteDialogBody .tabeebFoundUserList .tabeebFoundUser .tabeebFoundUserBody .tabeebInviteUserDate {
  font-size: 12px;
}
* {
  margin: 0;
  padding: 0;
}
iframe[width="0"][height="0"] {
  display: none !important;
}
html,
body {
  position: relative;
  height: 100%;
  font-size: small;
}
.tabeebApp {
  display: flex;
  flex-direction: column;
  height: 100%;
}
body ::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background-color: #F5F5F5;
}
body ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
body ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #6f6f6f;
}
