.content-viewer-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.annotation-selection-area {
  cursor: url(~@tabeeb/assets/icons/selection-area-cursor.svg), auto;
}

.page-info-controls {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  z-index: 9;
}
