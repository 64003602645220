.tabeebPresentationToolbar .tabeebPresentationNavbar {
  justify-content: center;
  position: relative;

  .playerPanel {
    display: flex;
    flex-flow: row nowrap;

    .playerControl {
      height: 100%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #616161;

      &.button {
        cursor: pointer;

        .volumeSlider {
          display: none;
        }

        &:hover {
          background-color: #c4c4c4;

          .volumeSlider {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &.timer {
        border-left: 1px solid #c4c4c4;
        time:first-child {
          color: #505050;
        }
        span {
          color: #a2a2a2;
        }
        time:last-child {
          color: #a2a2a2;
        }
      }
    }

    &.playerLeftPanel {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }

    &.playerRightPanel {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      .button {
        font-size: 19px;
      }
    }

    span {
      margin: 0 5px;
    }
  }
}

.playerProgressBar {
  height: 15px;

  input[type='range'] {
    -webkit-appearance: none;
    margin: 0;
    height: 15px;
    width: 100%;
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 15px;
      cursor: pointer;
    }
    &::-moz-range-track {
      width: 100%;
      height: 15px;
      cursor: pointer;

      background-color: #e3fce4;
    }

    &::-moz-range-progress {
      background-color: #4caf50;
      height: 15px;
    }

    &::-webkit-slider-thumb {
      width: 10px;
      height: 15px;
      cursor: pointer;

      box-shadow: 0px 0px 0px #000000;
      background: #bcc4bc;
      border: 0px solid #000000;

      -webkit-appearance: none;
      margin-top: 0px;
      animate: 0.2s;
    }
    &::-moz-range-thumb {
      width: 10px;
      height: 15px;
      cursor: pointer;

      box-shadow: 0px 0px 0px #000000;
      background: #bcc4bc;
      border: 0px solid #000000;
    }
  }
}
