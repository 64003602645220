.expand-gallery-button {
  position: absolute;
  background-color: white;
  left: 37px;
  bottom: 50%;
  font-size: 21px;
  cursor: pointer;
  display: flex;
  max-width: 13px;
  justify-content: center;
  padding-left: 2px;
}
