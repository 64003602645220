.pdfSearchResults {
  height: 100%;
  padding-top: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.pageResultsContainer {
  border-top: 2px solid #e2e2e2;
  position: relative;
  margin-bottom: 20px;
}

.result {
  word-wrap: break-word;
  position: relative;
  width: 160px;
  margin: 0 0 10px 22px;
  padding: 5px;
  cursor: pointer;
  border: 1px solid lightgrey;

  &:hover {
    border-color: #c8d90d;

    .triangleBorder {
      border-color: transparent transparent #c8d90d;
    }
  }
}

.selectedResult {
  border: 2px solid #c8d90d;
  .triangleBorder {
    border-color: transparent transparent #c8d90d;
  }
}

.pageResultsTitle {
  width: 80px;
  font-size: 12px;
  color: lightgrey;
  background-color: white;
  text-align: center;
  position: relative;
  z-index: 10;
  top: -10px;
  left: 30%;
  cursor: pointer;

  .resultTitleText {
    margin-left: 10px;
    position: relative;
    right: -5px;
  }

  .chevron-up,
  .chevron-down {
    font: bold 28px/100% arial, sans-serif;
    color: #e2e2e2;
    width: 22px;
    height: 22px;
    position: absolute;
    bottom: -2px;

    &:before {
      content: '\203a';
    }
  }

  .chevron-up {
    left: 5px;
    transform: rotate(-90deg);
  }

  .chevron-down {
    left: 13px;
    transform: rotate(90deg);
  }
}

.pdfSearchMatch {
  color: red;
  font-weight: bold;
}

.triangleBorder {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  right: -1px;
  bottom: -1px;
  border-width: 0 0 34px 34px;
  border-color: transparent transparent #e2e2e2;

  &:hover {
    border-color: transparent transparent #c8d90d;
  }
}

.pageNumber {
  position: absolute;
  right: 4px;
  color: grey;
  bottom: 2px;
  font-size: 10px;
  pointer-events: none;
}

.disabledPage {
  display: none;
}
