.share-by-form-dialog {
  .share-by-form-dialog-title {
    padding-bottom: 10px;
  }

  .share-by-form-dialog-content {
    width: 750px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-right: 0;

    .share-by-form-dialog-label {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .share-by-form-dialog-actions {
    padding: 5px 15px 8px 15px !important;

    .share-by-form-dialog-btn {
      font-size: 14px;
    }
  }

  .MuiDialog-paperWidthSm {
    max-width: unset;
  }
}
