.form-records {
  padding: 10px;
  display: flex;
  align-items: baseline;

  .save-form-record-btn {
    justify-content: center;
  }
}

.form-records-menu {
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.delete-form-record-btn {
  color: #616161;
  position: relative;
  top: 2px;
  padding-left: 5px;
  z-index: 1301;

  &:hover {
    color: red;
  }
}
