.gallery-list {
  flex-grow: 1;
  list-style-type: none;
  position: relative;
  margin-bottom: 0px;

  .gallery-help-text {
    position: absolute;
    top: 52%;
    right: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #e6e6e6;
  }
}

.add-all-pages {
  margin: 5px 22px 0 22px;
}

.add-all-pages-text {
  font-size: 14px;
  margin-left: 25px;
}

.add-all-pages-button {
  width: 22px;
  height: 22px;
  float: right;
  margin-right: 5px;
  background-color: black;
  border-radius: 50%;
  font: bold 22px/100% arial, sans-serif;
  color: white;

  text-transform: lowercase;
  text-decoration: none;
  cursor: pointer;

  &:before {
    content: '+';
    margin-left: 4.5px;
  }
}
