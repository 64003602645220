.file-uploads {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  box-shadow: inset -10px 0px 10px 0px #eeeeee;
  font-size: 14px;
  background-color: #fff;
  color: #616161;
  position: relative;
}
