.activity-filters-item {
  width: 50%;
  display: flex;

  .activity-filters-item-control {
    width: 95%;
  }
}

#activity-filters-menu {
  .MuiList-root {
    max-width: 186px;

    .activity-filters-menu-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
