.shared-folders-item {
  flex-shrink: 1;
  flex-basis: 32%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  min-width: 130px;
  max-height: 40px;
  margin-right: 0.5%;
  margin-left: 0.5%;
  margin-bottom: 20px;
  padding: 8px 10px;
  border-radius: 7px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background-color: white;

  &:hover {
    box-shadow: 0 0 7px #616161;
  }

  .folders-item-info {
    display: flex;
    align-items: center;
    width: 86%;

    .layout {
      position: absolute;
      z-index: 3;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      cursor: pointer;
    }

    .folders-item-name {
      color: #6f6f6f;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      top: 1px;
    }

    .icon-folder {
      display: flex;
      margin-right: 12px;
      font-size: 23px;
    }
  }

  .form-item-icon {
    &:hover {
      color: red;
      cursor: pointer;
    }
  }
}
