.tabeebInviteDialog {
    width: 600px !important;
    padding: 5px;

    .tabeebInviteDialogBody {
        height: 300px;
        overflow-y: auto;

        .tabeebFoundUserList {            
            list-style: none;
            display: flex;
            flex-direction: column;

            .tabeebFoundUser {
                display: flex;
                flex-direction: row;
                margin: 5px 0;
                justify-content: space-between;
                
                .tabeebFoundUserBody {
                    display: flex;
                    align-items: center;

                    .tabeebInviteUser {
                      margin-left: 10px;
                      display: flex;
                      flex-direction: column;
                      flex-grow: 1;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                    .tabeebInviteUserName {
                        margin-left: 0;
                    }

                    .tabeebInviteUserDate {
                      font-size: 12px;
                    }
                }
            }
        }
    }
    
}