.formsTextArea {
  border-radius: 5px;
  padding: 5px;

  &:disabled {
    background-color: #eeeeee;
    color: #616161;
    border: 1px dotted #616161;
    cursor: not-allowed;
  }
}
