.existing-reminder {
  display: flex;
  flex-direction: column;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 5px;
  cursor: pointer;
  position: relative;

  .existing-reminder-users {
    font-size: 15px;
    max-width: 275px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .existing-reminder-info {
    font-size: 12px;
    color: #616161;
  }

  &:hover {
    background-color: #e6e6e6;
  }

  .existing-reminder-delete-btn {
    border: 1px solid #e6e6e6;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    position: absolute;
    top: -12px;
    right: 8px;
    background-color: #fff;
    color: #616161;

    &:hover {
      background-color: red;
      color: #fff;
    }
  }
}
