.pdfSearch {
  width: 160px;
  margin: 10px 0 15px 22px;
  position: relative;

  i {
    line-height: 1.75em;
  }
}

.tabeeb-icon-search {
  position: absolute;
  top: 5px;
  right: 7px;
  transform: scaleX(-1);
  font-size: 12px;
  color: #bcbcbc;
}

.pagesLink,
.resultsLink {
  cursor: pointer;
}

.pagesLink {
  margin: 0 20px 0 25px;
}

.resultsLink {
  margin-right: 3px;
}

.pdfSearchInput {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  padding-left: 10px;
  display: block;
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  font-size: 12px;
  color: #7c7c7c;
}

.selectedLink {
  color: #c8d90d;
  font-weight: bold;
}

.matchesCount {
  color: white;
  background-color: #c8d90d;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  padding: 3px 5px;
  font-size: 10px;
  font-weight: 400;
  border-radius: 10px;
}
