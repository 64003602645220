.popover-class,
.popover-bottom-class {
  position: relative;
  height: 370px;
  margin-left: 15px;
  padding-left: 13px;

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #6f6f6f;
  }

  .MuiPopover-paper {
    background-color: transparent !important;
    box-shadow: none !important;
    overflow: unset;

    .MuiPaper-root {
      margin-left: 15px;
      border: 1px solid lightgray;
    }

    .control-info-window-arrow {
      position: fixed;
      z-index: 2;

      &::before {
        content: '';
        position: absolute;
        left: -2px;
        border-bottom: 6px solid transparent;
        border-right: 12px solid white;
        border-left: 6px solid transparent;
        border-top: 6px solid transparent;
        z-index: 3;
      }
    }

    .control-info-window-arrow-background {
      position: fixed;
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        left: -6px;
        top: -1px;
        border-bottom: 7px solid transparent;
        border-right: 14px solid lightgrey;
        border-left: 7px solid transparent;
        border-top: 7px solid transparent;
        z-index: 3;
      }
    }
  }

  > div:first-child {
    border: 1px solid lightgray;
    border-radius: 4px;
    overflow-x: hidden;
  }

  .control-info {
    width: 420px;
    height: 370px;
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
    overflow-y: auto;

    .control-info-content {
      margin-bottom: 10px;

      .control-info-content-img,
      .control-info-content-video {
        width: 100%;
        border-radius: 4px;
      }

      .mp-component_azure-mp-container__idgeq {
        background: transparent;

        .vjs-button {
          width: 30px;
        }

        .amp-controlbaricons-left {
          width: 8%;
          max-width: 45px;

          .vjs-play-control {
            display: block !important;
            width: 45px;
          }
        }

        .amp-controlbaricons-middle {
          width: 94%;
          padding: 0 5px 0 15px;

          .vjs-current-time,
          .vjs-duration {
            display: table-cell !important;
            text-align: unset;
            padding: 0;
          }

          .vjs-progress-control {
            padding: 0 15px 0 0px !important;
            bottom: 0;
          }
        }

        .amp-controlbaricons-right {
          width: 21%;
          max-width: 60px;

          .vjs-menu-button {
            display: none;
          }

          .vjs-menu-content {
            width: 30px;
          }

          .vjs-volume-control {
            display: inline-block !important;
          }
        }
      }
    }

    .control-info-label {
      margin-bottom: 15px;
      display: block;
      font-weight: bold;
      font-size: 16px;
    }

    .control-info-description {
      white-space: pre-wrap;
    }
  }
}
