.policies-update-dialog {
  height: 100%;

  .policies-update-dialog-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow-y: auto;
    width: 1000px;

    &.mobile {
      width: auto;
    }

    .tabs-container {
      &.mobile {
        min-height: 60px;
      }

      .MuiTab-wrapper {
        flex-direction: row;
      }
    }

    .update-icon {
      position: absolute;
      bottom: 5px;
      right: 5px;
      background: red;
      color: #fff;
      border-radius: 10px;
      padding: 0px 5px;
    }
  }
}
