.timeline-buttons-container {
  width: auto;
  height: 26px;
  position: absolute;
  right: 0;
  top: -26px;
  font-weight: bold;

  .timeline-button {
    font-size: 14px;
    width: auto;
    height: 26px;
    right: 0;
    font-weight: bold;
    background-color: whitesmoke;
    color: gray;
    padding: 3px 11px;
    cursor: pointer;
    border: none;
    margin-right: 3px;

    &[disabled] {
      background: gray;
      color: whitesmoke;
      cursor: not-allowed;
    }

    &:hover {
      color: whitesmoke;
    }

    &:first-child {
      border-radius: 5px 0 0 0;
    }

    &:last-child {
      border-radius: 0 5px 0 0;
      margin-right: 0px;
    }

    &-close:not([disabled]) {
      &:hover {
        background: red;
      }
    }

    &-submit:not([disabled]) {
      &:hover {
        background: green;
      }
    }
  }
}
