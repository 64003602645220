.play-audio-button {
  font-size: 15px;
  margin-right: 5px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 50%;
  width: 20px;
  line-height: 18px;
  display: inline-block;
  text-align: center;
  padding-left: 4px;
}
