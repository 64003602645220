.form-answer-status-statistics-filter-icon {
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.form-answer-status-filter-menu {
  width: 300px;
  display: flex;
  flex-flow: column;
  padding: 10px 25px 15px;

  .form-answer-status-filter-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-answer-status-filter-select-all {
      text-transform: none !important;
    }
  }

  .form-answer-status-filter-options {
    padding-left: 25px;
  }

  .form-answer-status-filter-apply {
    display: flex;
    align-items: center;
    justify-content: center;

    .form-answer-status-filter-apply-btn {
      width: 50%;
      border-radius: 12px;
    }
  }
}
