.content-viewer-loading-spinner-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  .content-viewer-loading-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 400px;
    background: url('~@tabeeb/assets/images/spinner.gif') no-repeat center;
    background-size: 50%;
  }
}
