.create-session-folder-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 0 5px;
  margin: 5px 10px;
  height: 40px;
  border-radius: 5px;

  &.expanded {
    width: 187px;
  }

  &:hover {
    opacity: 0.7;
  }

  .layout {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    border-radius: 5px;
    cursor: pointer;
  }

  .create-session-folder-button-label {
    z-index: 1;
    display: block;
    font-weight: bold;
    color: #616161;
    font-size: 14px;
    overflow: hidden;
  }

  .create-session-folder-icon {
    z-index: 1;
    color: #6f6f6f;
    margin-right: 5px;
  }
}
