.tenant-form_item {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 5px;
  margin: 0 5px;
  border-radius: 5px;
}

.tenant-form_item-name {
  padding-right: 3px;
}
