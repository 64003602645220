.forms-filters {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px;
}

.forms-filters-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  width: 50%;
}

.forms-filters-item-control {
  width: 95%;

  .MuiSelect-select {
    &:focus {
      background-color: transparent;
    }
  }
}

#forms-filters-menu {
  .MuiList-root {
    max-width: 200px;
    max-height: 250px;

    .forms-filters-menu-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
