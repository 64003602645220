.searchActivitiesFilter {
  padding: 0;
  padding-top: 12px;
  input.searchActivities {
    font-size: 15px;
    width: 100%;
    padding: 5px;
    padding-right: 25px;
    outline: none;
    border: none;
    border-bottom: 2px solid #eeeeee;
    background-image: url('~@tabeeb/assets/icons/search_24px.svg');
    background-repeat: no-repeat;
    background-position: right center;

    &::placeholder {
      color: #d7d7d7;
    }

    &[disabled] {
      background-color: #eeeeee;
      cursor: not-allowed;
    }
  }
}
