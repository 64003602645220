section.activities {
  p {
    margin: 0;
    padding: 0;
  }

  flex-grow: 1;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  box-shadow: inset 10px 0px 10px 0px #eeeeee;
  height: 100%;
  background-color: #ffffff;
  /*margin-right: 50px;*/
  font-size: 13px;
  color: #616161;

  header.filterPanel {
    padding: 22px;

    .pagesFilter {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: stretch;

      & > div {
        flex-grow: 1;
        line-height: 45px;
        vertical-align: middle;
        text-align: center;
        background-color: #eeeeee;
        border-bottom-color: #eeeeee;
        cursor: pointer;

        &.selectedPageFilter {
          cursor: auto;
          background-color: #fff;
          border-bottom: 2px solid black;
        }
      }
    }

    .pagesFilter > div {
      width: 50%;
    }

    .paramFilters {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: baseline;
      padding-top: 10px;
    }

    .usersFilterBox {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: baseline;

      .labelUsersFilter {
        color: #616161;
      }

      .usersFilter {
      }
    }

    .activityTypeFilterBox {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: baseline;

      .labelActivityTypeFilter {
      }
      .activityTypeFilter {
      }
    }
  }

  ul.activitiesList {
    direction: rtl;

    flex-grow: 1;
    list-style-type: none;
    overflow-y: auto;

    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;

    li.activity-item {
      direction: ltr;
      text-align: left;

      border-bottom: 2px solid #eeeeee;
      padding: 5px 22px 5px 22px;
      min-height: 87px;

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: stretch;

      &:hover {
        background-color: #f6f6f6;
      }

      &.selected {
        background-color: #eeeeee;
      }

      .avatarBox {
        max-width: 44px;
        min-width: 44px;
        height: 44px;
        margin-right: 11px;
        text-align: center;
        line-height: 44px;
        border-radius: 50%;
        color: white;

        align-self: center;

        &.blankAvatar {
          background-color: #4caf50;
        }

        img {
          height: 44px;
          width: 44px;
          border-radius: 50%;
          object-fit: contain;
        }
      }

      .infoBox {
        flex-grow: 1;

        overflow: hidden;

        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: stretch;

        article {
          flex-grow: 1;

          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: stretch;
          overflow-x: hidden;
          max-width: 295px;
        }

        footer {
          span {
            float: left;
            overflow-x: hidden;
            max-width: 190px;
            text-overflow: ellipsis;
          }
          time {
            float: right;
          }
        }
      }
    }
  }
}
