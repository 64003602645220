.form-top-buttons {
  width: 100%;
  display: flex;
  padding: 0 10px 10px;

  button {
    margin-right: 10px;
    height: 24px;
    font-size: 13px;
    text-transform: none;

    &:last-child {
      margin-right: 0;
    }
  }
}
