.notification {
  .notification-content {
    display: flex;
  }

  .content-description {
    text-align: justify;
    max-width: 310px;
  }

  .description-text {
    line-height: 1.5em;
    height: 3em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .content-avatar {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .notification-buttons {
    display: flex;
    justify-content: space-around;
    width: 70%;
    margin: 0 auto;
  }
}
