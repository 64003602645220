.found-user-item {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;
  height: 53px;
  max-height: 60px;
  overflow-y: hidden;

  &:last-child {
    border-bottom: none;
  }

  .found-user-item-info {
    display: flex;
    align-items: center;

    .found-user-item-avatar {
      width: 40px;
      height: 40px;
      margin-right: 25px;
      border-radius: 50%;
    }

    .found-user-item-name {
      max-width: 275px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .found-user-item-button-title {
    font-size: 10px;
    font-weight: bold;
  }
}
