.reminder-custom-repeat-interval-container {
  margin-bottom: 30px;

  .reminder-custom-repeat-interval {
    display: flex;
    align-items: baseline;

    .reminder-custom-repeat-interval-label {
      width: 30%;
      font-size: 15px;
    }

    .reminder-custom-repeat-interval-value {
      width: 15%;
      margin-right: 15px;
    }

    .reminder-custom-repeat-interval-type {
      width: 30%;
    }
  }
}

.reminder-custom-repeat-interval-value {
  background-color: aliceblue;
  height: 29px;
  padding: 0 5px 0 10px;
  border-radius: 3px 3px 0 0;
  border: none;
  border-bottom: 2px solid #0089cf;
}
