.available-forms-dialog {
  position: relative;
}

.forms__availableToAttach {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  overflow-y: auto;
  max-height: 70vh;

  & > * {
    margin: 10px 0;
  }
}

.available-forms-dialog-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.available-forms-search {
  margin: 10px 15px;
}

.no-available-forms-message {
  margin: 20px auto;
  font-size: 20px;
  text-align: center;
}

.available-forms-title-container {
  &.MuiDialogTitle-root {
    max-height: 60px;

    & .MuiTypography-root.MuiTypography-h6 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.available-forms-exit-button {
  &.MuiButtonBase-root.MuiIconButton-root {
    margin: 0px 4px 4px 0px;
  }
}

@media screen and (max-width: 1825px) {
  .forms__availableToAttach {
    justify-content: space-around;
  }
}

@media screen and (max-width: 1100px) {
  .forms__availableToAttach {
    justify-content: space-around;
  }
}
