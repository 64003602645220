.reminder-custom-repeat-days {
  margin-bottom: 25px;

  .reminder-custom-repeat-days-label {
    display: block;
    margin-bottom: 8px;
    font-size: 15px;
  }

  .reminder-custom-repeat-day {
    color: #616161;
    background: #e6e6e6;
    width: 26px;
    height: 26px;
    font-size: 12px;
    margin-right: 8px;
    cursor: pointer;
  }

  .selected {
    color: #fff;
    background: #0089cf;
  }

  .reminder-custom-repeat-days-list {
    display: flex;
  }
}
