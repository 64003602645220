.user-options-controls-popover {
  max-width: 400px;

  .MuiPaper-rounded {
    border-radius: 9px;
  }

  .section {
    .section-title {
      display: flex;
      color: rgba(0, 0, 0, 0.84);
      margin: 16px 16px 0;

      .title-text {
        margin-left: 5px;
      }
    }
    .section-content {
      max-height: 200px;
      overflow: auto;
    }
  }
}
