.reminder-date-container {
  padding: 5px;
  margin-bottom: 10px;
  color: #616161;

  .reminder-date {
    display: flex;
    align-items: center;

    .reminder-date-icon {
      font-size: 16px;
      margin-right: 10px;
    }

    .reminder-date-picker {
      width: 90%;
    }
  }
}
