.activity-info-footer {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .activity-page-info {
    display: flex;
    flex-direction: column;
  }
}
