@font-face {
  font-family: 'tabeeb';
  src: url('~@tabeeb/assets/fonts/tabeeb.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^='tabeeb-icon-'] {
  font-family: 'tabeeb' !important;
  font-style: normal;
  font-weight: normal;
  speak: none;
  font-variant: normal;
  text-transform: none;

  .active {
    color: #0089cf;
  }

  .disabled {
    color: darkgray;
  }
}

/* 'î ‡' */
.tabeeb-icon-play:before {
  content: '\e808';
}

/* 'î ‰' */
.tabeeb-icon-search:before {
  content: '\e80a';
}

/* 'î §' */
.tabeeb-icon-trash-empty:before {
  content: '\e828';
}

// .tabeeb-icon-lock:before {
//   content: '\1F512';
// }

.tabeeb-icon-edit-black:before {
  content: '\e857';
}

.tabeeb-icon-lock:before {
  content: '\e85b';
}

/* '' */

//Todo: add as a font

.icon-chat {
  background: url('~@tabeeb/assets/icons/ic_chat_white_24px.svg') 100% no-repeat;
}

.icon-forms {
  background: url('~@tabeeb/assets/icons/ic_forms_in_white_24px.svg') 100% no-repeat;
}

.icon-activities {
  background: url('~@tabeeb/assets/icons/search_24px.svg') 100% no-repeat;
}

.icon-file-uploads {
  background: url('~@tabeeb/assets/icons/cloud_upload-24px.svg') 100% no-repeat;
}

.icon-users {
  background: url('~@tabeeb/assets/icons/ic_group_black_24px.svg') 100% no-repeat;
}

.icon-custom-video {
  background: url('~@tabeeb/assets/icons/ic_custom_video_black_24px.svg') 100% no-repeat;
}

.icon-spatial-view {
  background: url('~@tabeeb/assets/icons/ic_spatial_view_24px.svg') 100% no-repeat;
}

.icon-3d-model {
  background: url('~@tabeeb/assets/icons/baseline-3d_rotation-24px.svg') 100% no-repeat;
}

.icon-add-users {
  background: url('~@tabeeb/assets/icons/ic_add_users_24px.svg') 100% no-repeat;
}

.icon-gallery {
  background: url('~@tabeeb/assets/icons/ic_gallery_black_24px.svg') 100% no-repeat;
}

.icon-share {
  background: url('~@tabeeb/assets/icons/icon_share_24px.svg') 100% no-repeat;
}

.icon-review {
  background: url('~@tabeeb/assets/icons/icon_review_24px.svg') 100% no-repeat;
}

.icon-structure-model {
  background: url('~@tabeeb/assets/icons/square_foot-24px.svg') 100% no-repeat;
}

.icon-tower-side-profile {
  background: url('~@tabeeb/assets/icons/cell_tower_black_24dp.svg') 100% no-repeat;
}

.tabeeb-icon-export-csv {
  background: url('~@tabeeb/assets/icons/baseline-get_app-24px.svg') 100% no-repeat;
}

.tabeeb-icon-advanced-filter {
  background: url('~@tabeeb/assets/icons/funnel-noborder-20px.svg') 100% no-repeat;
}

.tabeeb-icon-progress {
  background: url('~@tabeeb/assets/icons/Rolling-1s-24px.svg') 100% no-repeat;
}

.icon-bentley-model {
  background: url('~@tabeeb/assets/icons/baseline-b-letter-24px.svg') 100% no-repeat;
}

.icon-bentley-analytics-report {
  background: url('~@tabeeb/assets/icons/baseline-measure-area-24px.svg') 100% no-repeat;
}
