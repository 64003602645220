.closed {
  display: none !important;
}

.timeline-container {
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 5px;
  position: absolute;
  top: 0;
  left: 0;

  .timeline-list {
    height: 100%;
    width: 100%;
    padding: 0px 10px;
    display: inline-flex;
    flex-grow: 1;
    overflow-x: auto;
    align-items: center;
  }
}
