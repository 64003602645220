.forms__availableToAttach_item {
  flex-direction: row;
  align-self: flex-start;
  border: 2px solid black;
  border-radius: 5px;
  width: 32%;
  max-height: 270px;
  padding: 15px 5px;

  &:hover {
    box-shadow: 0 0 5px red;
  }

  .attach-form-btn {
    background-color: white;
    color: #616161;
  }

  .detach-form-btn {
    background-color: #0089cf;
    color: white;

    &:hover {
      background-color: #0089cf;
      color: white;
      opacity: 0.9;
    }
  }

  .attach-form-btn,
  .detach-form-btn {
    font-size: 14px;
    float: right;
    margin: 10px;
  }
}

.forms__availableToAttach_item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
  font-size: 18px;
  color: black;
}

.forms__availableToAttach_item-info {
  float: left;

  & > span {
    display: block;
    margin: 10px;
  }
}

.forms__availableToAttach_item-img {
  width: 100%;
  min-height: 130px;

  & img {
    width: 100%;
    max-height: 130px;
  }
}

@media screen and (max-width: 1825px) {
  .forms__availableToAttach_item {
    width: 45%;
  }
}

@media screen and (max-width: 1050px) {
  .forms__availableToAttach_item {
    width: 85%;
  }
}

@media screen and (max-width: 680px) {
  .forms__availableToAttach_item {
    width: 95%;
  }
}
