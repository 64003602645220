.reminder-users-container {
  color: #616161;
  margin-bottom: 20px;
  padding-right: 5px;

  .reminder-users {
    display: flex;

    .reminder-users-icon {
      font-size: 23px;
      margin-right: 10px;
      display: flex;
      align-self: center;
    }
  }
}

.reminder-users-select {
  width: 90%;

  .reminder-users-select-placeholder {
    color: #616161;
  }
}

#reminder-users-select-menu {
  .MuiList-root {
    max-height: 250px;
  }
}

.reminder-user {
  .MuiAvatar-root {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

.reminder-users-selected {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
