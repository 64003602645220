.gallery-button-container {
  .gallery-button {
    width: 100%;
  }

  flex-grow: 1;
  max-width: 170px;
  width: 45%;

  &:last-of-type {
    margin-left: 5px;
  }
}

.gallery-controls-panel-buttons {
  display: flex;

  &.expanded {
    max-width: 374px;
  }
}
