.sessionfolders-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;
  z-index: 2;
  margin: 5px 0;

  &.expanded {
    border-bottom: 3px solid #ececec;
  }

  .list-container {
    height: auto;

    &.expanded {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
