.file-upload-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;

  .switch-on-hover-buttons {
    > :first-child {
      display: none;
    }

    > :last-child {
      display: grid;
    }

    &:hover {
      > :first-child {
        display: grid;
      }

      > :last-child {
        display: none;
      }
    }
  }

  .file-upload-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    align-self: center;
    vertical-align: middle;
    flex-grow: 1;
    margin: 0px;
  }

  .file-upload-progress-bar {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
  }

  .file-upload-status-icon {
    height: 32px;
    width: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.file-upload-status-error {
      color: red;
    }

    &.file-upload-status-success {
      color: #0f9d58;
    }
  }
}
