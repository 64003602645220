.session-folders-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 0 5px;
  height: 40px;

  &[isdragon='true'] {
    background-color: rgba(0, 0, 0, 0.15);
  }

  &.expanded {
    min-width: 187px;
  }

  &:hover {
    box-shadow: 0 0 7px #616161;

    .session-folders-item-name[showcontrols='true'] {
      max-width: 110px;
    }

    .tabeeb-icon-edit-black,
    .tabeeb-icon-trash-empty {
      display: block;
    }
  }

  .layout {
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    cursor: pointer;
  }

  .tabeeb-rename-folder-textbox {
    z-index: 5;
  }

  .session-folders-item-name {
    display: block;
    font-weight: bold;
    color: #616161;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 130px;
  }

  .session-folders-item-icon {
    color: #6f6f6f;
    margin-right: 5px;
    margin-left: 10px;
  }

  .tabeeb-icon-edit-black {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 20px;
    color: #616161;
    z-index: 4;

    &::before {
      font-size: 15px;
    }

    &:hover {
      color: rgb(0, 110, 255);
    }
  }

  .tabeeb-icon-trash-empty {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 4px;
    color: #616161;
    z-index: 4;

    &::before {
      font-size: 15px;
    }

    &:hover {
      color: red;
    }
  }

  .gallery-item-add-button {
    position: absolute;
    top: 9px;
    right: 8px;
    z-index: 4;
    font-size: 20px;
    color: #eeeeee;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: #95989a;
    text-align: center;

    &.selected {
      color: green;
      background-color: #fff;
      font-size: 22px;
    }
  }
}
