.user-options-controls-popover {
  max-width: 400px;

  .MuiPaper-rounded {
    border-radius: 9px;
  }

  .section {
    .section-title {
      display: flex;
      color: rgba(0, 0, 0, 0.84);
      margin: 16px 16px 0;

      .title-text {
        margin-left: 5px;
      }
    }
    .section-content {
      max-height: 450px;
      overflow: auto;
      position: relative;

      .video {
        height: 150px;
        width: 267px;
        transform: rotateY(180deg);
        background: linear-gradient(#505050, #606060);
      }

      .camera-preview {
        display: flex;
        justify-content: center;
        align-items: end;

        .preview-label {
          position: absolute;
          margin: 0 auto;
          margin-top: auto;
          padding: 4px;
          background: rgba(0, 0, 0, 0.35);
          border-radius: 4px;
          color: #ffffff;
        }
      }
    }
  }
}
