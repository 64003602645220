.empty-policy-placeholder-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.policy-viewer-container {
  padding: 0px 25px;
  position: relative;
  line-height: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 150%;
  max-width: 1000px;
  overflow-y: auto;
  margin: 0px auto;
  flex-grow: 1;

  p {
    font-size: 14px;
    word-wrap: normal;
    line-height: 36px;
    margin: 0 0 30px;
    font-weight: normal;
  }
}
