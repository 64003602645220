.share-by-form-timeline-list-item {
  display: flex;
  flex-shrink: 0;
  height: 125px;
  min-width: 50px;
  max-width: 170px;
  position: relative;
  padding: 0px;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  margin-right: 5px;
  background: white;
  border: 1px solid #e4e4e4;

  &:last-child {
    margin-right: 0;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  span {
    .del-button {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 22px;
      height: 22px;

      background-color: black;
      border-radius: 50%;

      font: bold 22px/100% arial, sans-serif;
      color: white;

      text-transform: lowercase;
      text-decoration: none;
      cursor: pointer;

      &:before {
        content: '\2212';
        margin-left: 4px;
      }
    }

    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 34px;
      height: 34px;

      background-color: #eeeeee;
      border: 3px solid black;
      border-radius: 50%;

      font: bold 20px/28px arial, sans-serif;
      color: black;

      text-transform: lowercase;
      text-decoration: none;
      text-align: center;

      cursor: pointer;

      &:before {
        content: '\25BA';
        margin-left: 4px;
      }
    }
  }
}
