.tabeebPresentationToolbar {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  float: left;
  width: 60%;
  margin-right: 20px;
  flex-grow: 1;
  overflow: hidden;
  min-width: 200px;
  user-select: none;

  .tabeebPresentationContent {
    position: relative;
    border: 0px #e0e0e0 solid;
    width: 100%;
    height: ~"calc(100% - 50px - 40px - 200px)";
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: #404040;
    border: 1px solid #c4c4c4;

    .largeVideoWrapper {
      background-color: #000000;
      width: 100%;
      height: 100%;
      overflow: auto;
      position: absolute;
      text-align: center;
      z-index: 10;
      line-height: 0;

      .largeVideo {
        height: 100%;
        margin: auto;
      }

    }
    .largeVideoUserName {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      color: #ffffff;
      padding: 5px;
      min-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 13px;
      z-index: 100;
      text-align: center;

      &.zoomed {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
}

.cont {
  width: 100%;
  height: 200px;
  position: relative;
}
