.share-session-dialog {
  .share-session-dialog-content {
    min-width: 550px;
    display: flex;
    flex-direction: column;

    .share-session-dialog-input {
      width: 100%;
      margin-bottom: 20px;
    }

    .share-session-dialog-label {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .share-session-dialog-actions {
    padding: 25px 15px 8px 15px !important;

    .share-session-dialog-btn {
      font-size: 14px;
    }
  }
}
