.form-error-message-bar {
  width: 100%;
  border: 2px solid red;
  border-radius: 5px;
  padding: 15px 10px;
  background-color: rgba(255, 0, 0, 0.3);
  color: white;
  margin-bottom: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 100px;
}
