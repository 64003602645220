.policy-error-bar-container {
  &.MuiPaper-root {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin: 20px;
    border: solid red 1px;
    background-color: #ff0000c2;
    color: white;
  }
}
