.timeline-list-form-group {
  padding: 7px;
  background-color: rgb(236, 236, 236);
  margin-right: 15px;
  border-radius: 7px;
  min-width: fit-content;
}

.no-form {
  height: 100%;
  min-width: fit-content;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.timeline-list-form-group-contents {
  min-width: max-content;
  display: flex;
  align-items: center;
}

.timeline-list-form-group-form-name {
  display: inline-flex;
  align-items: baseline;
  position: relative;
  top: 4px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;

  .timeline-list-form-group-form-name-title {
    color: #6f6f6f;
  }

  .timeline-list-form-group-form-name-icon {
    font-size: 12px;
    margin-right: 5px;
    position: relative;
    top: 1px;
  }
}
