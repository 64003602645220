.form-input {
  .input-label {
    width: 100%;
    font-weight: normal;
    color: #616161;
  }

  .input {
    width: 100%;
  }

  .error {
    font-size: small;
    font-style: italic;
    color: red;
  }

  &.-error {
    .input {
      background-color: rgba(255, 0, 0, 0.07);
    }
  }
}
