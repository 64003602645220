.tabeebSidebar {
  height: calc(~"100%") !important;
}

.sidebarApp {
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding-top: 12px;
}

.tabeebScrollable {
  -webkit-overflow-scrolling: touch;
}

.contentWrapper {
  height: 100%;  
}
