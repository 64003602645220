.notification {
  max-width: 400px;
  width: 97%;
  display: flex;
  position: absolute;
  z-index: 1000;
  top: 48px;
  padding: 22px 17px 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

  animation-duration: 1s;
  animation-name: slidein;

  background-color: rgba(255, 255, 255, 0.95);

  .navigation-container {
    display: flex;
    position: absolute;
    width: 96%;
    justify-content: end;
    align-items: flex-end;
    padding: 2px 3px 1px;
    align-self: flex-end;
    justify-self: flex-end;
    padding: 12px;
  }
}
