.no-navbar {
  .tabeebSidebarPanelContainer {
    padding-right: 0;
  }
}

.tabeebSidebarPanelContainer {
  height: 100%;
  z-index: 10;
  padding-right: @navbarWidth;

  &.no-open-panels {
    padding-right: 0;
    pointer-events: none;
    width: 0;
  }

  &.no-open-panels {
    > div {
      display: none !important;
    }
  }

  > .tabeebSidebarPanel[data-panel-name="forms"] {
    overflow-y: hidden !important;
  }

  > .tabeebSidebarPanel {
    height: 100%;
    background: #3c3e43;
    display: none;
    color: white;
    transition: right 0.5s;
    // overflow-y: auto;
    max-height: 100%;
    position: relative;    

    /*
    This hack is so videos can still be displayed on the tabeeb canvas when the video panel isn't open
    */
    &.tabeebSidebarPresentationPanel {
      display: block;
      width: 100% !important;
      height: 37px !important;
      &.active {
        width: 100% !important;
        height: 100% !important;
      }
      &:not(.active) {
        > div {
          // This will hide everything but the large video and tabeeb controls since these will get overridden
          position: absolute;
          left: -5000px;
        }
      }      
    }

    &.active {
      display: block;
    }
  }
}

.sidebarContainer {
  .tabeebSidebarPresentationPanel {
    .tabeebControls {
      display: none;
    }
    &:not(.active) {
      max-height: 0 !important;
    }
  }
  &.presentationMode {
    .tabeebSidebarPresentationPanel {
      .tabeebControls {
        display: block;
      }
    }
    .tabeebSidebarPresentationPanel:not(.active) {
      overflow: hidden;
      max-height: 100% !important;
      .tabeebControls {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
      }
    }
  }
}