.chat-message-asset-audio {
  min-width: 250px;

  .rhap_progress-indicator {
    background: #000000;
  }

  .rhap_progress-filled {
    background: #000000;
  }

  .rhap_download-progress {
    background: #868686;
  }
}
