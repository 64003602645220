.shared-for-review-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 0 0;

  .shared-for-review-form-name {
    font-size: 17px;
    max-width: 90%;
    color: lightgray;
  }
}

.shared-for-review-form-message {
  font-size: 12px;
  display: block;
}
