.tenant-settings-control {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3.2857142857142856rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .tenant-settings-label {
    flex: 1px;
    margin-left: 19.06%;
    letter-spacing: 0;
    color: #454546;
    opacity: 1;
    display: flex;
    align-items: center;

    label {
      font-weight: normal;

      &.with-help-text {
        max-width: 90%;
      }
    }

    @media (max-width: 768px) {
      margin-left: 0%;
    }
  }

  .tenant-settings-input {
    margin-right: 8.4%;
    max-width: 313px;
    position: relative;

    @media (max-width: 768px) {
      margin-right: 0%;
    }
  }
}
