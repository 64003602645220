.gallery-item {
  .block-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 1;
  }
}

.gallery-item-control-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;

  .gallery-item-control {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-size: 20px;
    color: #eeeeee;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: #95989a;
    margin: 1px 2px;
  }
}
