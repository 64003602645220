.reminder-repeat {
  display: flex;
  align-items: center;
  padding: 5px;
  color: #616161;

  .reminder-repeat-icon {
    font-size: 18px;
    margin-right: 8px;
    display: flex;
  }

  .reminder-repeat-select {
    width: 90%;
  }
}

.reminder-repeat-select-item {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
