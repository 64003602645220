.switch-session {
  display: inline-block;

  > .tabeeb-icon-search {
    display: inline-block;
    position: inherit;
    vertical-align: middle;
    margin-left: 10px;
    cursor: pointer;
  }
}

.available-sessions-popover {
  min-width: 200px;
  max-height: 40% !important;
}

.available-sessions-list {
  overflow-y: auto;
  height: 200px;
  padding: 5px 0px;
  font-size: 16px;

  .available-session {
    padding: 4px 0px 4px 0px;
    cursor: pointer;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > .tabeeb-icon-lock {
      padding-right: 5px;
      color: rgb(117, 117, 117);
    }
    &:hover {
      background-color: #e5e5e5;
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: #efefef;
    }
  }
}

.available-sessions-container {
  width: 400px;
  padding: 5px 30px;
}
