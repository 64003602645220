.file-uploads-controls-container {
  box-shadow: 0px -7px 10px -3px #eeeeee;
  border-top: 1px solid white;
  display: flex;
  padding: 14px 10px;

  .file-uploads-control {
    flex-grow: 1;
    max-width: 170px;

    &:last-of-type {
      margin-left: 5px;
    }
  }
}
