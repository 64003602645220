@import "variables.less";
.tabeebSideBarNavbar {
  background-color: #c4c4c4;
  display: table;  
  display: flex;
  flex-direction: column;
  min-width: @navbarWidth;
  max-width: @navbarWidth;
  margin: 0;
  list-style: none;

  li {        
    cursor: pointer; 
    position: relative;   
    
    span {
      display: block;
      width: 24px;
      height: 24px;
      margin: 12px 13px;
      color: rgba(0, 0, 0, 0.54);
    }

    b {
      position: absolute;
      top: 5px;
      left: 25px;
      background-color: #F44336;
      border-radius: 3px;
      display: block;
      min-width: 18px;
      height: 18px;
      text-align: center;
      color: #ffffff;
    }
  }

  li[data-name='chat'],
  li[data-name='fileUploads'] {
    position: relative;
  }

  .active {
    background-color: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }
}

.tabeebSideBarNavbar-bottom {
  flex-direction: row;
  min-width: auto;
  max-width: auto;
  margin-top: 20px;
}

li[data-name='custom-video'],
li[data-name='spatial-view'],
li[data-name='3d-model'],
li[data-name='bentley-model'],
li[data-name='bentley-analytics-report'],
li[data-name='share-session'],
li[data-name='review-session'],
li[data-name='structure-model'],
li[data-name='linked-contents-pages'],
li[data-name='tower-side-profile'],
li[data-name='2d-line-drawing'],
li[data-name='classification-annotations'],
li[data-name='splat-model']
{
  background-color: #c4c4c4;
  color: #333;
}
