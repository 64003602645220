.gallery-navbar-container {
  height: 50px;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid gray;
  display: flex;
}

.gallery-navbar-back-arrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 48px;
  width: 48px;
  font-size: 20px;
  padding: 0px 5px;

  &:hover {
    background-color: rgba(128, 128, 128, 0.3);
  }
}

.gallery-navbar-folder-name {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 140px;
}
