@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.gallery-item {
  padding: 22px 22px 0px 22px;
  background-color: #fff;
  position: relative;
  z-index: 2;

  &.expanded {
    border-bottom: 5px solid #ececec;
  }

  figure {
    text-align: center;
    width: 160px;

    &.added-to-selection {
      border: 2px dashed steelblue;
      padding: 2px;
    }

    &.expanded {
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      font-size: 16px;
    }

    .figure-content {
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      outline: 1px solid #eeeeee;
      margin: 0 0 10px;

      &.not-available {
        cursor: not-allowed;
      }

      &.expanded {
        width: 160px;
      }

      &:hover {
        outline-color: #007aff;
        .gallery-item-page {
          border-color: transparent transparent #007aff transparent;
          i {
            color: white;
          }
        }
      }

      .image-placeholder {
        min-height: 35px;
        visibility: hidden;
      }

      img {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;

        &.loaded {
          opacity: 1;
          animation: fadeIn ease 0.5s;
        }

        &.blocked {
          opacity: 0.3;
        }
      }

      .gallery-item-del-button,
      .gallery-item-add-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 3px;
        right: 3px;
        z-index: 1;
        font-size: 20px;
        color: #eeeeee;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        background-color: #95989a;
      }

      .gallery-item-add-button {
        &.selected {
          color: green;
          background-color: #fff;
        }
      }

      .play-button {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 34px;
        height: 34px;

        background-color: #eeeeee;
        border: 3px solid black;
        border-radius: 50%;

        font: bold 20px/28px arial, sans-serif;
        color: black;

        text-transform: lowercase;
        text-decoration: none;
        text-align: center;

        cursor: pointer;

        &:before {
          content: '\25BA';
          margin-left: 4px;
        }
      }

      .gallery-item-page {
        position: absolute;
        z-index: 1;
        bottom: 0px;
        right: 0px;

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 25px 30px;
        border-color: transparent transparent #fff transparent;

        i {
          bottom: -25px;
          right: 3px;
          position: absolute;
          padding: 0;
          font-size: 9px;
          color: grey;
          font-style: normal;
        }
      }
    }

    figcaption {
      span {
        &.gallery-item-span {
          cursor: text;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &[disabled] {
            cursor: not-allowed;
          }

          &.expanded {
            white-space: normal;
            flex-wrap: nowrap;
            text-align: start;
            margin-bottom: 10px;
            font-weight: bold;
          }
        }
        display: block;
      }

      &.expanded {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-start;
        flex: 0.95;
        padding-left: 10px;
      }

      input {
        width: 100%;
      }
    }
  }

  &.selected {
    > figure {
      > .figure-content {
        .gallery-item-page {
          border-color: transparent transparent #007aff transparent;
          i {
            color: white;
          }
        }
        outline: #007aff solid 2px;
      }
    }
  }
}
