.container-scrollable {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;

  font-size: 13px;
  color: #616161;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;
}

.gallery {
  height: 100%;
  width: 204px;
  display: flex;
  flex-direction: column;
  position: relative;
  outline: none;
  background-color: #ffffff;
  box-shadow: inset -10px 0px 10px 0px #eeeeee;

  &.expanded {
    width: 100%;
  }

  &.hide {
    visibility: hidden;
  }

  .gallery-export-options-buttons {
    display: flex;
    justify-content: center;
    padding-top: 7px;

    .gallery-options-button {
      width: 90%;
    }
  }
}

.gallery-toolbar {
  height: 100%;
  display: flex;
  width: auto;
  align-items: flex-start;
  flex-direction: row-reverse;
  position: relative;

  &.expanded {
    flex: 1;
    z-index: 10;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.drag-and-drop-container {
  height: 100%;
  width: 100%;
  border: 1px dashed gray;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: inset rgb(200, 200, 200) 0px 0px 100px 30px;

  &.visible {
    opacity: 1;
    animation: fadeIn ease 0.5s;
  }

  .drag-and-drop-label {
    font-size: 25px;
    word-break: break-word;
    text-align: center;
  }
}
