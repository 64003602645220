.form-buttons-container {
  padding: 7px 10px;

  .form-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 7px 0;
    max-width: 400px;

    button {
      width: 50%;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
