.annotation-info-list {
  list-style: none;
  padding: 0px 15px 0px 25px;
  max-height: 309px;
  overflow-y: auto;
  margin: 0px;
}

.annotation-popover-body {
  padding: 20px;
}
