@import "icons.less";
@import "common.less";
@import "navbar.less";
@import "panels.less";
@import "presentation.less";
@import "dialog.less";

* {
  margin: 0;
  padding: 0;
}

iframe[width="0"][height="0"] {
  display: none !important;
}

html, body {
  position: relative;
  height: 100%;
  font-size: small;
}

.tabeebApp {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  ::-webkit-scrollbar
  {
    width: 9px;
    height: 9px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #6f6f6f;
  }
}
