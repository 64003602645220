.reminder-custom-repeat-end {
  .reminder-custom-repeat-end-label {
    font-size: 15px;
  }

  .reminder-custom-repeat-end-option {
    display: flex;
    align-items: center;

    .MuiFormControlLabel-root {
      width: 120px;
    }

    .reminder-custom-repeat-end-occur {
      padding-top: 3px;

      .reminder-custom-repeat-interval-value {
        width: 38%;
        margin-right: 5px;

        &:disabled {
          background-color: #e6e6e6;
          border-color: transparent;
        }
      }

      .reminder-custom-repeat-interval-label {
        font-size: 15px;
        display: inline-flex;
      }
    }

    .reminder-custom-repeat-end-date,
    .reminder-custom-repeat-end-occur {
      max-width: 150px;
      border-radius: 3px;
    }

    .reminder-custom-repeat-end-date-disabled,
    .reminder-custom-repeat-end-occur-disabled {
      background-color: #e6e6e6;
      color: #616161;
    }
  }
}
