.sharing-tenants-list {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  top: 1px;

  .MuiSelect-select {
    &:focus {
      background-color: transparent;
    }
  }
}
